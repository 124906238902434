import { useHistory } from "react-router-dom";
import {
  formatNumber,
  playerData,
  Tbadges,
  getAvatarURL,
  getProfileURL,
} from "../../SharedGlobals";

import "./RankingEntry.css";
//@ts-ignore
import pogu from "../img/pogu.png";
//@ts-ignore
import bow from "../img/bowing.svg";
import { ReactElement } from "react-markdown";

export const badges: Tbadges = { 5795337: [pogu, "no homo"], 6502403: [bow, "catgirls"] };

type rankingEntryProps = {
  playerData: playerData;
  showCountry: boolean;
  showGains?: boolean;
  flag: any; //url ('default' field from module)
  gainsDays?: number; //if undefined then in 'players' mode
  pos?: number; //used in 'gains' mode because there is no pos field in playerData
};

export const RankingEntry = ({
  playerData,
  showCountry,
  showGains,
  flag,
  gainsDays,
  pos,
}: rankingEntryProps) => {
  const history = useHistory();
  let el_gainIndicator: ReactElement | null = null;
  let el_gainCount: ReactElement | null = null;
  let el_gained: ReactElement | null = null;
  let mode = "player";
  if (gainsDays) mode = "gains";
  else if ((playerData as any).id) mode = "gainsRanking";
  const id = (playerData as any).id ?? playerData._id;

  if (showGains) {
    if (playerData.g50 != null) {
      if (mode !== "gainsRanking") {
        if (!playerData.gPos) {
          el_gainIndicator = <td className="line" title="No change" />;
        } else if (playerData.gPos > 0) {
          el_gainIndicator = (
            <td
              className="arrow arrow-down"
              title={`Lost ${playerData.gPos} rank${playerData.gPos === 1 ? "" : "s"}`}
            />
          );
        } else {
          el_gainIndicator = (
            <td
              className="arrow"
              title={`Gained ${playerData.gPos * -1} rank${playerData.gPos === -1 ? "" : "s"}`}
            />
          );
        }
      }

      if (gainsDays) {
        el_gained = (
          <td
            className="mr-1"
            title={`Gained top 50s over ${gainsDays} day${gainsDays > 1 ? "s" : ""}`}
          >
            {`${formatNumber(playerData.g50)}`}{" "}
            {gainsDays > 1 && `(${Math.round((playerData.g50 / gainsDays) * 100) / 100}/day)`}
            {" | "}
          </td>
        );
      } else if (mode === "player") {
        el_gainCount = (
          <td className="ml-1" title="Top 50s gained since previous archive entry">
            {`(${playerData.g50 > 0 ? "+" : ""}${playerData.g50})`}
          </td>
        );
      } else {
        el_gained = (
          <td className="ml-1" title="Top 50s gained in 1 day">
            {`${playerData.g50}`}
          </td>
        );
      }
    } else if (mode !== "gainsRanking") {
      //WARNING: Will also appear when checking show gains after fetching with it unchecked
      if (mode === "gains") return null; //if in gains mode and without gained top 50s then not a valid entry
      el_gainIndicator = <td className="circle" title="New" />;
    }
  }

  let el_av = (
    <td>
      <a
        className="ml-1 mr-2"
        href={getProfileURL("osu", id)}
        rel="noreferrer"
        target="_blank"
        title="osu! profile"
      >
        <img className="small-img avatar" height="2rem" src={getAvatarURL(id)} alt="av" />
      </a>
    </td>
  );

  let el_nick = (
    <td className="ranking-entry-username">
      <a
        href={getProfileURL("osustats", playerData.curNam || playerData.nam)}
        rel="noreferrer"
        target="_blank"
        title="osu!stats"
      >
        {playerData.nam}
      </a>
      {playerData.curNam && (
        <small className="ml-2" title="Current username">{`(${playerData.curNam})`}</small>
      )}
    </td>
  );

  let el_cntrRank: ReactElement | null = null;
  let el_flag: ReactElement | null = null;
  if (showCountry && playerData.cntr) {
    if (mode === "player") {
      el_cntrRank = (
        <td className="mr-1 rank-country" title="Country rank">
          #{playerData.cntrPos}
        </td>
      );
    }

    el_flag = (
      <td style={{ display: "inline-flex" }}>
        <img
          className="small-img flag mr-2"
          src={flag}
          title={playerData.cntr}
          alt={playerData.cntr}
        />
      </td>
    );
  }

  return (
    <tr
      className="ranking-entry"
      id={`r${playerData.pos || pos?.toString()}`}
      onClick={(e) => {
        if (e.target !== e.currentTarget) return;
        history.push(`/player/${id}`);
      }}
    >
      <td style={{ width: "5ch", textAlign: "start" }}>
        <strong className="ml-1">#{playerData.pos || pos} </strong>
      </td>
      {el_gainIndicator /*indicator arrow*/}
      {el_av /*avatar*/}
      {el_cntrRank}
      {el_flag}
      {el_nick}
      {el_gained /*only in 'gains' and 'gainsRanking' modes*/}
      {mode === "gainsRanking" ? (
        <td className="ranking-entry-gain-before-after">
          <span className="nowrap">{formatNumber(playerData.t50 - playerData.g50)}</span>
          <span> → </span>
          <span className="nowrap">{formatNumber(playerData.t50)}</span>
        </td>
      ) : (
        <td>{formatNumber(playerData.t50)}</td>
      )}
      {el_gainCount /*only in 'players' mode*/}
      {badges[id] !== undefined ? (
        <td style={{ display: "flex" }}>
          <img
            className="ml-3"
            src={badges[id][0]}
            title={badges[id][1]}
            style={{ height: "26px" }}
            alt="<3"
          />
        </td>
      ) : null}

      {mode === "gainsRanking" ? (
        <td className="mr-1 ranking-entry-date" style={{ fontWeight: 300, marginLeft: "auto" }}>
          {(playerData as any).date}
        </td>
      ) : null}
    </tr>
  );
};
