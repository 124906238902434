import React from "react";
import { START_DATE } from "../../SharedGlobals";
import Datepicker from "../Datepicker";
import "./DateToolbar.css";

export default function DateToolbar({
  dateChange,
  sendDateChange,
  date,
  maxDate,
}: {
  dateChange: (event: any) => void;
  sendDateChange: any;
  date: string;
  maxDate?: string;
}) {
  const sendDate = () => {
    sendDateChange(0, true);
  };

  return (
    <div className="toolbar toolbar-date">
      <button
        className="btn btn-green"
        onClick={() => {
          sendDateChange(-1);
        }}
      >
        {"<"}
      </button>
      <div className="group">
        <Datepicker
          date={date}
          min={START_DATE}
          max={maxDate}
          onchange={dateChange}
          onkeyenter={sendDate}
        />
        <button className="btn btn-blue" onClick={sendDate}>
          yoink
        </button>
      </div>
      <button
        className="btn btn-green"
        onClick={() => {
          sendDateChange(1);
        }}
      >
        {">"}
      </button>
    </div>
  );
}
