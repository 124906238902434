import "./index.css";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import RankingLayout from "./layouts/Ranking/RankingLayout";
import FetcherLayout from "./layouts/Fetcher/FetcherLayout";
import RankingUserStatsLayout from "./layouts/Ranking/RankingUserStatsLayout";
import ProfileLayout from "./layouts/Profile/ProfileLayout";
import HomeLayout from "./layouts/Home/HomeLayout";
import NotFoundLayout from "./layouts/NotFound/NotFoundLayout";
import SenkoLayout from "./layouts/Senko/SenkoLayout";
import AboutLayout from "./layouts/About/AboutLayout";
import ChangelogLayout from "./layouts/Changelog/ChangelogLayout";

const tabs = [
  { text: "Home", link: "/home" },
  { text: "Ranking", link: "/ranking" },
  { text: "Fetcher", link: "/fetcher" },
  { text: "About", link: "/about", right: true },
];

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <SenkoLayout />
        </Route>

        <Route exact path="/home">
          <MainLayout
            navbar_tabs={tabs}
            navbar_tabs_active={[tabs[0].text]}
            display_footer={true}
            children={<HomeLayout />}
            class_append="main-home"
          />
        </Route>

        <Route
          path="/ranking/:mode?/:date?/:country?/:position?/:gainsDays?"
          render={() => (
            <MainLayout
              navbar_tabs={tabs}
              navbar_tabs_active={[tabs[1].text]}
              display_footer={true}
              children={<RankingLayout />}
              class_append="main-ranking"
            />
          )}
        />
        <Route
          path="/player/:id/:timeFrame?"
          render={() => (
            <MainLayout
              navbar_tabs={tabs}
              navbar_tabs_active={[tabs[1].text]}
              display_footer={true}
              children={<RankingUserStatsLayout />}
              class_append="main-ranking"
            />
          )}
        />

        <Route
          path="/profile/:id"
          render={(props) => (
            <MainLayout
              navbar_tabs={tabs}
              navbar_tabs_active={[]}
              display_footer={true}
              children={<ProfileLayout id={props.match.params.id}></ProfileLayout>}
              class_append="main-ranking"
            />
          )}
        />

        <Route path="/fetcher/:player?/:ranks?">
          <MainLayout
            navbar_tabs={tabs}
            navbar_tabs_active={[tabs[2].text]}
            display_footer={true}
            children={<FetcherLayout />}
            class_append="main-fetcher"
          />
        </Route>

        <Route path="/about">
          <MainLayout
            navbar_tabs={tabs}
            navbar_tabs_active={["About"]}
            display_footer={true}
            children={<AboutLayout />}
            class_append="main-about"
          />
        </Route>

        <Route path="/changelog">
          <MainLayout
            navbar_tabs={tabs}
            navbar_tabs_active={[]}
            display_footer={true}
            children={<ChangelogLayout />}
            class_append="main-changelog"
          />
        </Route>

        <Route component={NotFoundLayout} />
      </Switch>
    </BrowserRouter>
  );
}
