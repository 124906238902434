import React from "react";
import { countryData } from "../../SharedGlobals";
import { RankingCountryEntry } from "./RankingCountryEntry";
import "./RankingCountry.css";

const useSortableData = (items: any, config: { key: string; asc: boolean }) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.asc ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.asc ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: string, asc: boolean) => {
    setSortConfig({ key, asc });
  };

  return { sortedItems, requestSort };
};

export default function RankingCountryTable({
  data,
  flags,
}: {
  data: Array<countryData>;
  flags: { [index: string]: any };
}) {
  const [sortConfig, setSortConfig] = React.useState({ key: "w", asc: false });
  const { sortedItems, requestSort } = useSortableData(data, {
    key: sortConfig.key,
    asc: sortConfig.asc,
  });

  const requestSortConfig = (key: string) => {
    if (key === sortConfig.key) setSortConfig({ ...sortConfig, asc: !sortConfig.asc });
    else setSortConfig({ ...sortConfig, key });
  };

  React.useEffect(() => {
    requestSort(sortConfig.key, sortConfig.asc);
    // eslint-disable-next-line
  }, [data, sortConfig]);

  const getClassName = (name: string) => {
    return sortConfig.key === name
      ? sortConfig.asc
        ? "sortable asc"
        : "sortable desc"
      : "sortable";
  };

  return (
    <table className="table-country">
      <thead className="table-background">
        <tr>
          <th>#</th>
          <th className={getClassName("fnm")} onClick={() => requestSortConfig("fnm")}>
            Country
          </th>
          <th className={getClassName("w")} onClick={() => requestSortConfig("w")}>
            Weighted Count
          </th>
          <th className={getClassName("tot")} onClick={() => requestSortConfig("tot")}>
            Total Count
          </th>
          <th className={getClassName("am")} onClick={() => requestSortConfig("am")}>
            Players
          </th>
          <th>Average Count</th>
        </tr>
      </thead>
      <tbody>
        {sortedItems.map((e: countryData, id: number) => (
          <RankingCountryEntry
            key={id}
            countryData={e}
            flag={flags[`${e.nm.toLowerCase()}.svg`]}
            countryPos={id + 1}
          />
        ))}
      </tbody>
    </table>
  );
}
