import React from "react";
import { Link } from "react-router-dom";
import { TabProps } from "../SharedGlobals";

export const Tab = (props: TabProps) => {
  let classes = "tab";
  if (props.active) {
    classes += " tab-active";
  }
  if (props.right) {
    classes += " tab-right";
  }

  return (
    <Link className={classes} to={props.link}>
      {props.text}
    </Link>
  );
};

export class Navbar extends React.Component<{
  tabs: TabProps[];
  activeTabs: string[];
}> {
  state = {
    activeTabs: this.props.activeTabs,
  };

  render() {
    return (
      <nav className="navbar">
        {this.props.tabs.map((t) => (
          <Tab
            key={t.text}
            text={t.text}
            link={t.link}
            right={t.right}
            active={this.props.activeTabs.includes(t.text)}
          />
        ))}
      </nav>
    );
  }
}
