import React, { Component } from "react";

type props = {
  title?: string;
  text: string;
  color: string;
};

export default class Alert extends Component<props> {
  render() {
    let color = this.props.color;
    if (color === "red") {
      color = "hsl(0, 80%, 52%)";
    } else if (color === "blue") {
      color = "hsl(210, 80%, 52%)";
    } else if (color === "yellow") {
      color = "hsl(50, 90%, 52%)";
    }

    return (
      <div className={"alert-wrap"}>
        <div style={{ backgroundColor: color }} className="alert-accent"></div>
        <div className="alert">
          {this.props.title ? <h2>{this.props.title}</h2> : null}
          {this.props.text}
        </div>
      </div>
    );
  }
}
