import "./index.css";
import React from "react";
import { Link } from "react-router-dom";

export default function NotFoundLayout() {
  React.useEffect(() => {
    document.title = "not poggers.";
  }, []);

  return (
    <div className="container-404">
      <h1>This page does not exist!</h1>
      <p>no poggers.</p>
      <br />
      <Link to="/">Return to poggers</Link>
    </div>
  );
}
