"use strict";
exports.__esModule = true;
exports.MIN_GAINS_NEGATIVE = exports.MIN_GAINS_POSITIVE = exports.MAX_GAINS_DEPTH = exports.START_DATE = exports.trimArray = exports.padDate = exports.getAvatarURL = exports.getProfileURL = exports.formatNumber = void 0;
function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
exports.formatNumber = formatNumber;
function getProfileURL(profile, idOrName) {
    if (!idOrName)
        return;
    return profile === "osustats"
        ? "https://osustats.ppy.sh/u/" + idOrName + "//1//////1-50"
        : "https://osu.ppy.sh/u/" + idOrName;
}
exports.getProfileURL = getProfileURL;
function getAvatarURL(id) {
    return "https://a.ppy.sh/" + id + "_0.png"; //the 0 is the timestamp, should use latest to avoid caching
}
exports.getAvatarURL = getAvatarURL;
function padDate(date) {
    return date[0] + "-" + date[1].padStart(2, "0") + "-" + date[2].padStart(2, "0");
}
exports.padDate = padDate;
function trimArray(a) {
    while ((a === null || a === void 0 ? void 0 : a.length) && a[a.length - 1] == null)
        a.pop();
    while (a.length && a[0] == null)
        a.shift();
    return a;
}
exports.trimArray = trimArray;
exports.START_DATE = "2020-05-10";
exports.MAX_GAINS_DEPTH = 32;
exports.MIN_GAINS_POSITIVE = 6;
exports.MIN_GAINS_NEGATIVE = -5;
