import dayjs from "dayjs";
import { useState } from "react";
import { Collapse } from "react-collapse";
import { formatNumber, getAvatarURL, getProfileURL } from "../SharedGlobals";

type props = {
  def_expand: boolean;
  plr: {
    id?: number | string;
    nam?: string;
    color?: { t: string; r: string };
    stat: {
      min?: { val: number; d: string };
      max?: { val: number; d: string };
      days?: number;
      gained?: number;
    };
    t50?: number;
    vis_t?: boolean;
    vis_r?: boolean;
  };
  toggle50: () => void;
  toggleR: () => void;
};

export default function UserStatsPlayer({ def_expand, plr, toggle50, toggleR }: props) {
  const [expanded, setExpanded] = useState(def_expand);

  const expand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="chart-player-wrapper">
      <div className="chart-player">
        <span
          onClick={expand}
          className={"icon icon-expand mr-1"}
          style={{ transform: expanded ? "rotate(180deg)" : "rotate(90deg)" }}
        ></span>
        <a
          className="mr-3"
          href={getProfileURL("osu", plr.id)}
          rel="noreferrer"
          target="_blank"
          title="osu! profile"
          style={{ height: "2rem", width: "2rem" }}
        >
          <img
            className="small-img avatar"
            src={getAvatarURL(plr.id)}
            alt="av"
            style={plr.color && { boxShadow: `0 0 0.3rem 0.15rem ${plr.color.t}` }}
          />
        </a>
        <a
          href={getProfileURL("osustats", plr.nam)}
          rel="noreferrer"
          target="_blank"
          title="osu!stats"
          className="mr-3"
        >
          {plr.nam}
        </a>
        {plr.t50 && formatNumber(plr.t50)}
        <div style={{ marginLeft: "auto" }}>
          <span
            onClick={toggle50}
            className={
              plr.vis_t
                ? "chart-player-ico-enabled chart-player-50 gradient-text"
                : "chart-player-ico-disabled chart-player-50 gradient-text"
            }
          >
            50
          </span>
          <span
            onClick={toggleR}
            className={
              plr.vis_r
                ? "chart-player-ico-enabled chart-player-r gradient-text"
                : "chart-player-ico-disabled chart-player-r gradient-text"
            }
          >
            R
          </span>
        </div>
      </div>
      {/* @ts-ignore */}
      <Collapse isOpened={expanded}>
        {plr.stat.min && plr.stat.max && plr.stat.days ? (
          <div style={{ fontWeight: 300 }}>
            Peak: <strong style={{ fontWeight: 400 }}>{formatNumber(plr.stat.max.val)}</strong>{" "}
            ({dayjs(plr.stat.max.d).format("D MMM, YYYY")}) Minimum:{" "}
            <strong style={{ fontWeight: 400 }}>{formatNumber(plr.stat.min.val)}</strong> (
            {dayjs(plr.stat.min.d).format("D MMM, YYYY")})
            <br />
            {plr.stat.gained ? (
              plr.stat.gained > 0 ? (
                <>
                  Gained{" "}
                  <strong style={{ fontWeight: 400 }}>{formatNumber(plr.stat.gained)}</strong>{" "}
                  over {plr.stat.days} days
                </>
              ) : (
                <>
                  Lost{" "}
                  <strong style={{ fontWeight: 400 }}>
                    {formatNumber(-1 * plr.stat.gained)}
                  </strong>{" "}
                  over {plr.stat.days} days
                </>
              )
            ) : null}
            {plr.stat.gained ? (
              <>
                , averaging{" "}
                <strong style={{ fontWeight: 400 }}>
                  {Math.round((plr.stat.gained / plr.stat.days) * 100) / 100}
                </strong>
                /day
              </>
            ) : null}
            <br />
          </div>
        ) : null}
      </Collapse>
    </div>
  );
}
