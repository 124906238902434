import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { TabProps } from "./SharedGlobals";

type props = {
  navbar_tabs: TabProps[];
  navbar_tabs_active: string[];
  children: React.ReactNode;
  class_append?: string;
  display_footer?: boolean;
};

const MainLayout = ({
  navbar_tabs,
  navbar_tabs_active,
  children,
  class_append,
  display_footer,
}: props) => {
  return (
    <>
      <header>
        <Link to="/">poggers</Link>
      </header>
      <Navbar tabs={navbar_tabs} activeTabs={navbar_tabs_active} />
      <main className={"main " + class_append}>{children}</main>
      {display_footer && (
        <footer>
          poggers limited 2022
          <small>
            <Link
              style={{
                paddingTop: "0.25rem",
                display: "block",
                width: "fit-content",
                marginRight: "auto",
                marginLeft: "auto",
              }}
              to="/changelog"
            >
              {"ver 2022-05-07 (aka very non-final)"}
            </Link>
            so anyway, how's your day been?
          </small>
        </footer>
      )}
    </>
  );
};

export default MainLayout;
