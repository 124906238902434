import React, { Component } from "react";
import { Link } from "react-router-dom";

//@ts-ignore
import senkoimg from "./senko-poggers.png";
const poggers = require("./poggers.mp3");
const colors = ["0000ff", "ff00ff", "ff0000", "ffff00", "00ff00", "00ffff"];

export default class SenkoLayout extends Component {
  audio = new Audio(poggers);
  state = {
    curCount: 0,
  };

  componentDidMount() {
    document.title = "poggers";
  }

  senko = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.preventDefault();
    this.audio.currentTime = 0;
    this.audio.play();
    if (localStorage.owo) {
      ++localStorage.owo;
    } else {
      localStorage.owo = 1;
    }
    this.setState({ curCount: this.state.curCount + 1 });
  };

  render() {
    return (
      <div className="senko-body">
        <span className="counter" style={{ opacity: this.state.curCount ? "1" : "0" }}>
          {localStorage.owo}
        </span>

        <div id="senko" style={{ top: 75 - this.state.curCount * 0.25 + "%" }}>
          <span className="txt" style={{ opacity: localStorage.owo ? "0" : "1" }}>
            Click me!
          </span>
          <br />
          <img
            id="real-senko"
            src={senkoimg}
            alt="senko poggers"
            height="253"
            draggable={false}
            onClick={(e) => this.senko(e)}
            onContextMenu={(e) => this.senko(e)}
            style={{
              height: 26 + this.state.curCount * 0.5 + "%",
              boxShadow:
                "0 0 2vw 0 #" +
                (this.state.curCount
                  ? colors[(this.state.curCount - 1) % colors.length]
                  : "#aaa"),
            }}
          />
          <br />
          <Link to="/home">home</Link>
        </div>
      </div>
    );
  }
}
