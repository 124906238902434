import axios from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import Alert from "../components/Alert";
import "./ProfileLayout.css";

interface Props {
  id: number | string;
  name?: string;
}

interface Stats {
  topGain: { d: string[]; g50: number };
  stats: [
    [],
    {
      nam: string;
      min: { val: number; d: string };
      max: { val: number; d: string };
      days: number;
      first: number;
      last: number;
    }
  ];
  info: {
    nam: string;
    oldNam: string[];
    t50: number;
    g50: number;
    pos: number;
    cntr: string;
    cntrPos: number;
    date: string;
    cur: boolean;
  };
  last: { t50: Array<number | null>; gained: number; gainedAvg: number | null };
  date: string;
}

export default function ProfileLayout({ id }: Props): ReactElement {
  const [loading, setLoading]: [boolean, any] = useState(true);
  const [stats, setStats]: [null | Stats, any] = useState(null);
  const [alert, setAlert]: [null | HTMLElement, any] = useState(null);

  const getPlayer = async (id: number | string) => {
    try {
      const response = await axios.get("/api/profile/" + id);
      setStats(response.data);
      setLoading(false);
      return response.data;
    } catch (error) {
      console.error(error);
      setAlert(<Alert title="fukc" text={(error as Error).message} color="red"></Alert>);
      setStats(null);
      setLoading(false);
      return null;
    }
  };

  useEffect(() => {
    getPlayer(id);
  }, [id]);

  return (
    <div>
      <h3>how did you find this</h3>
      {loading && <div className="loader" />}
      {alert}
      {stats && (
        <>
          <h2>{(stats as Stats).stats[1].nam}</h2>
          {(stats as Stats).info.oldNam?.length ? (
            <p>Previously known as {(stats as Stats).info.oldNam.join(", ")}</p>
          ) : null}
          <div>
            #{(stats as Stats).info.pos} | {(stats as Stats).info.cntr}{" "}
            {(stats as Stats).info.cntrPos}
          </div>
          <h2 style={{ fontWeight: "normal" }}>Most top 50s gained in 1 day</h2>
          <strong>{(stats as Stats).topGain.g50}</strong>
          <br></br>
          {(stats as Stats).topGain.d.join(", ")}
          <br></br>
          <small>Data from {(stats as Stats).info.date}</small>
        </>
      )}
      <p>
        Please note that this only takes into account days where gained top 50s were unanimously
        determinable (i.e. skips gaps in ranking)
      </p>
    </div>
  );
}
