import React, { Component } from "react";
import CardButton from "../components/CardButton";

const btnImg = "url(https://osu.ppy.sh/images/backgrounds/button.svg)";

export class HomeLayout extends Component {
  componentDidMount() {
    document.title = "poggers - home";
  }

  render() {
    return (
      <div>
        <h1>pogowanie 2.0</h1>
        <br />
        <div className="home-buttons">
          <CardButton
            btnCardImg={btnImg}
            btnCardColor="hsl(114, 70%, 85%)"
            href="ranking"
            btnTxt="Ranking"
            tooltip="View past top 50 score rankings"
          />
          <CardButton
            btnCardImg={btnImg}
            imgPosY="bottom"
            btnCardColor="hsl(60, 70%, 85%)"
            href="https://pogowany.000webhostapp.com/fetcher/"
            btnTxt="Fetcher"
            tooltip="Check how many individual score ranks you have"
          />
          <CardButton
            btnCardImg={"url(/ht-thumb.jpg)"}
            imgPosY="top"
            btnCardColor="hsl(207, 70%, 85%)"
            href="https://ht.poggers.ltd"
            btnTxt="Hardware Tycoon"
            tooltip="A small strategy/management game of mine"
          />
        </div>
        <br />
      </div>
    );
  }
}

export default HomeLayout;
