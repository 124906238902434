import React from "react";
import { countryData, formatNumber } from "../../SharedGlobals";
import "./RankingCountry.css";

type props = {
  countryData: countryData;
  countryPos: number;
  flag: string;
};

export const RankingCountryEntry = ({ countryData, countryPos, flag }: props) => {
  return (
    <tr>
      <td className="table-background">{countryPos}</td>
      <td>
        <span style={{ display: "flex", alignItems: "center" }}>
          <img className="ml-2 mr-3 small-img flag" src={flag} alt={countryData.nm} />
          <span className="country-name">{countryData.fnm}</span>
        </span>
      </td>
      <td>{formatNumber(countryData.w)}</td>
      <td>{formatNumber(countryData.tot)}</td>
      <td>{countryData.am}</td>
      <td>{Math.round((countryData.tot / countryData.am) * 10) / 10}</td>
    </tr>
  );
};
