import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import UserStatsChart from "./UserStatsChart";

import UserStatsPlayer from "./UserStatsPlayer";
import { getAvatarURL, START_DATE } from "../SharedGlobals";
import Alert from "../components/Alert";

type Tuser = {
  name?: string;
  id?: string | number;
  stats?: string | Array<[number, number, number]>;
  max?: { val: number; d: string };
  min?: { val: number; d: string };
  days?: number;
  f?: number;
  l?: number;
  gained?: number;
  vis_t: boolean;
  vis_r?: boolean;
};

const dataColors = [
  { t: "hsl(218, 75%, 55%)", r: "hsl(0, 86%, 58%)" }, //blue
  { t: "hsl(127, 75%, 55%)", r: "hsl(11, 86%, 58%)" }, //green
  { t: "hsl(268, 75%, 55%)", r: "hsl(349, 86%, 58%)" }, //purple
  { t: "hsl(60, 75%, 55%)", r: "hsl(22, 86%, 58%)" }, //yellow
  { t: "hsl(185, 75%, 55%)", r: "hsl(338, 86%, 58%)" }, //light-blue
  { t: "hsl(300, 75%, 55%)", r: "hsl(33, 86%, 50%)" }, //light-purple
  { t: "hsl(30, 75%, 55%)", r: "hsl(327, 86%, 40%)" }, //orange
  { t: "hsl(85, 75%, 55%)", r: "hsl(0, 86%, 30%)" }, //light-green
];

const RankingUserStatsLayout = () => {
  const isInitialMount = useRef(true);
  const urlParams: any = useParams();
  const history = useHistory();

  const [users, setUsers]: [Array<Tuser>, any] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addPlr, setAddPlr] = useState("");
  const [autocmp, setAutocmp]: [Array<{ _id: number; nam: string }>, any] = useState([]);
  const [timeFrame, setTimeFrame] = useState(
    urlParams.timeFrame ? urlParams.timeFrame.split(":") : ["", ""]
  );

  useEffect(() => {
    document.title = "poggers stats";
    requestPlayerStats(Number(urlParams.id));
    // eslint-disable-next-line
  }, [urlParams.id]);

  const clickListener = (e: any) => {
    if ((e.target as Element).className !== "autocmp-inside") setAutocmp([]);
  };
  useEffect(() => {
    document.addEventListener("mousedown", clickListener);
    return () => {
      document.removeEventListener("mousedown", clickListener);
    };
  }, []);

  const timeFrameURL = (time: string[]) => {
    if (!time[0] && !time[1]) {
      history.push(`/player/${urlParams.id}`);
      return "";
    }
    history.push(`/player/${urlParams.id}/${time[0] || ""}:${time[1] || ""}`);
    return 1;
  };

  useEffect(() => {
    if (!isInitialMount.current) {
      timeFrameURL(timeFrame);
    } else {
      isInitialMount.current = false;
    }
    // eslint-disable-next-line
  }, [timeFrame]);

  const requestPlayerFromName = (name: string) => {
    if (name) {
      axios
        .get("/api/id/" + name)
        .then((response) => {
          if (
            response.data &&
            response.data.id &&
            users.find((a) => a.id === response.data.id) === undefined
          ) {
            requestPlayerStats(Number(response.data.id));
            setAddPlr("");
            setAutocmp([]);
          }
        })
        .catch((error) => {
          console.warn("Couldn't get id of " + name + ":\n" + error);
        });
    }
  };

  //TODO: always request full date range, and operate on it from memory?
  const requestPlayerStats = (id: number, updateUser?: boolean) => {
    //Get player count from ID by date and set users state
    setLoading(true);
    axios
      .get("/api/player-stats/" + id, {
        params: { dateStart: timeFrame[0], dateEnd: timeFrame[1] },
        responseType: "json",
      })
      .then((response) => {
        if (!response.data) return false;
        let userIndexInUsers = users.length;
        if (updateUser) {
          users.forEach((user, index) => {
            if (user.id === id) {
              userIndexInUsers = index;
              return false;
            }
          });
        } else if (!response.data[0].length) return false;

        let stats = response.data[0].map((data: any[]) => {
          return {
            Date: data[0],
            [`t50-${userIndexInUsers}`]: data[2],
            [`pos-${userIndexInUsers}`]: data[1],
          };
        });
        if (updateUser) {
          let usersTmp = [...users];
          usersTmp[userIndexInUsers].name = response.data[1].nam;
          usersTmp[userIndexInUsers].stats = stats;
          usersTmp[userIndexInUsers].min = response.data[1].min;
          usersTmp[userIndexInUsers].max = response.data[1].max;
          usersTmp[userIndexInUsers].days = response.data[1].days;
          usersTmp[userIndexInUsers].f = response.data[1].first;
          usersTmp[userIndexInUsers].l = response.data[1].last;

          setUsers(usersTmp);
        } else
          setUsers([
            ...users,
            {
              name: response.data[1].nam,
              id,
              stats,
              min: response.data[1].min,
              max: response.data[1].max,
              days: response.data[1].days,
              f: response.data[1].first,
              l: response.data[1].last,
              vis_t: true,
              vis_r: users.length < 1,
            },
          ]);
      })
      .catch((error) => {
        console.error("Couldn't get stats for " + id + ":\n" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggle_t = (id?: number | string) => {
    let usersTmp = [...users];
    usersTmp.forEach((user) => {
      if (user.id === id) {
        user.vis_t = !user.vis_t;
        return false;
      }
    });

    setUsers(usersTmp);
  };
  const toggle_r = (id?: number | string) => {
    let usersTmp = [...users];
    usersTmp.forEach((user) => {
      if (user.id === id) {
        user.vis_r = !user.vis_r;
        return false;
      }
    });

    setUsers(usersTmp);
  };

  //TODO: debounce
  const searchPlayer = (name: string) => {
    axios
      .get("/api/player-search/" + name, {
        responseType: "json",
      })
      .then((response) => {
        setAutocmp(response.data || []);
      })
      .catch((err) => {
        console.warn("Couldn't search: " + err);
        setAutocmp([]);
      });
  };

  const handlePlayerAutocmp = (plr: { _id: any; nam: string }) => {
    if (users.find((a) => a.id === plr._id) === undefined) requestPlayerStats(plr._id);
    setAddPlr("");
    setAutocmp([]);
  };

  return (
    <>
      <h1 style={{ fontWeight: 400 }}>Top 50s over time</h1>
      {users.map((user, index) => {
        if (!user.stats || !user.stats.length) return null;
        return (
          <UserStatsPlayer
            key={index}
            def_expand={index < 2}
            plr={{
              id: user.id,
              nam: user.name,
              color: {
                t: dataColors[index % dataColors.length].t,
                r: dataColors[index % dataColors.length].r,
              },
              stat: {
                min: user.min,
                max: user.max,
                days: user.days,
                gained: user.l && user.f ? user.l - user.f : undefined,
              },
              t50: user.l,
              vis_t: user.vis_t,
              vis_r: user.vis_r,
            }}
            toggle50={() => toggle_t(user.id)}
            toggleR={() => toggle_r(user.id)}
          />
        );
      })}
      {loading && <div className="loader"></div>}
      {users.length ? (
        <div className="chart-wrapper">
          {<UserStatsChart data={users} colors={dataColors} />}
        </div>
      ) : loading ? null : (
        <Alert
          title="No data!"
          color="yellow"
          text={
            "Sorry, it seems like no player with the given id exists in the database, or there is no data in the given time range.\nOnly players with over 1000 top 50s are tracked."
          }
        />
      )}
      <div className="chart-settings">
        <form
          style={{ display: "inline-flex" }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <button
            className="btn btn-green"
            onClick={() => {
              requestPlayerFromName(addPlr);
            }}
          >
            <span className="icon icon-add"></span>
            Add Player
          </button>
          <div className="autocmp-wrapper">
            <input
              type="text"
              value={addPlr}
              placeholder="Player Name"
              onChange={(e) => {
                setAddPlr(e.target.value);
                if (e.target.value.length >= 2) searchPlayer(e.target.value);
                else setAutocmp([]);
              }}
            />
            <div className="autocmp-items">
              {autocmp.map((plr) => {
                return (
                  <div
                    key={plr._id}
                    className="autocmp"
                    style={{ backgroundImage: "url(" + getAvatarURL(plr._id) + ")" }}
                    onClick={() => {
                      handlePlayerAutocmp(plr);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handlePlayerAutocmp(plr);
                      }
                    }}
                  >
                    <div className="autocmp-inside" tabIndex={0}>
                      {plr.nam}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </form>
        <form
          style={{ display: "inline-flex", alignItems: "center" }}
          onSubmit={(e) => {
            e.preventDefault();
            users.forEach((usr) => {
              requestPlayerStats(Number(usr.id), true);
            });
          }}
        >
          <input
            className="mr-1"
            name="startDate"
            type="date"
            min={START_DATE}
            value={timeFrame[0]}
            onChange={(e) => setTimeFrame([e.target.value, timeFrame[1]])}
          ></input>
          to
          <input
            name="endDate"
            type="date"
            min={START_DATE}
            value={timeFrame[1]}
            onChange={(e) => setTimeFrame([timeFrame[0], e.target.value])}
          ></input>
          <button className="btn btn-blue ml-1" type="submit">
            Go
          </button>
        </form>
      </div>
    </>
  );
};

export default RankingUserStatsLayout;
