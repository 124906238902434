import dayjs from "dayjs";
import React, { Component } from "react";
import { Collapse } from "react-collapse";
import ReactMarkdown from "react-markdown";
import { SecondaryNavbar } from "../components/SecondaryNavbar";
import "./Changelog.css";

class ChangelogEntry extends Component<{ date: string; txt: string; expanded: boolean }> {
  state = {
    expanded: this.props.expanded,
  };

  expand = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    return (
      <>
        <div className="change-entry-head" onClick={this.expand}>
          <span
            className="icon icon-expand mr-1"
            style={{ transform: this.state.expanded ? "rotate(180deg)" : "rotate(90deg)" }}
          ></span>
          <span>{dayjs(this.props.date).format("D MMMM YYYY")}</span>
        </div>
        <Collapse isOpened={this.state.expanded}>
          <ReactMarkdown>{this.props.txt}</ReactMarkdown>
        </Collapse>
      </>
    );
  }
}
export default class ChangelogLayout extends Component {
  state: {
    changes: Array<{ dat: string; txt: string } | null>;
    tab: "changelog" | "to do";
    loading: boolean;
  } = {
    changes: [],
    tab: "changelog",
    loading: true,
  };

  async componentDidMount() {
    fetch("/api/site/changelog")
      .then((response) => response.json())
      .then((data) => {
        for (const [dat, txt] of Object.entries(data)) {
          this.setState({
            changes: [...this.state.changes, { dat, txt }],
          });
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    return (
      <>
        <SecondaryNavbar
          tabs={["Changelog", "To do"]}
          activeTab={this.state.tab}
          tabClick={(e: any) => {
            this.setState({ ...this.state, tab: e.target.innerHTML.toLowerCase() });
          }}
          margin="2rem"
        />
        <div className="container-center">
          {this.state.loading && <div className="loader"></div>}
          {this.state.tab === "changelog" ? (
            this.state.changes.map((dat, index) => {
              return dat ? (
                <ChangelogEntry key={dat.dat} date={dat.dat} txt={dat.txt} expanded={!index} />
              ) : null;
            })
          ) : (
            <>
              <h3>Stuff I still want to add:</h3>
              <ul>
                <li>Finish misc stats</li>
                <li>Fetcher 2.0 (with more stats :D)</li>
                <li>Other players in user stats as url parameters</li>
                <li>Make ranking settings look the part</li>
                <li>Ranking pagination</li>
                <li>Tooltips and general hints on usage of the site</li>
                <li>More user stats (such as most gains in one day)</li>
                <li>Top 50s over time chart zoom and/or pan</li>
                <li>Exporting user stats (to csv/txt)</li>
                <li>Global senkos count</li>
                <li>Option to show top 50s needed for next rank</li>
                <li>Sort autosuggested players by top 50 count</li>
                <li>Max dates and clear buttons in user stats for time frame date inputs</li>
                <li>Changelog lazy loading</li>
                <li>Images for home buttons</li>
                <li>A proper about page</li>
                <li>Dark mode?</li>
                And counting...
              </ul>
            </>
          )}
          <hr />
          <div style={{ textAlign: "center" }}>
            <small>First build: 28 March 2021</small>
          </div>
        </div>
      </>
    );
  }
}
