import axios from "axios";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Collapse } from "react-collapse";
import Alert from "../components/Alert";
import DateToolbar from "../components/DateToolbar/DateToolbar";
import { RankingEntry } from "./RankingEntry/RankingEntry";
import { formatNumber, playerData, START_DATE } from "../SharedGlobals";
import { SecondaryNavbar } from "../components/SecondaryNavbar";

import RankingCountryTable from "./RankingCountry/RankingCountryTable";

type displayT = {
  mode: string;
  showGained: boolean;
  gainsDays: number | string;
  showCountry: boolean;
  country: string;
  firstEntry: number;
  lastEntry: number;
  displayMode: string;
};

const modeTabs = ["Players", "Gains", "Countries", "Misc"];

type countryT = { _id: string; fnm: string };
const countries: countryT[] = [
  {
    _id: "US",
    fnm: "United States",
  },
  {
    _id: "RU",
    fnm: "Russian Federation",
  },
  {
    _id: "ID",
    fnm: "Indonesia",
  },
  {
    _id: "TW",
    fnm: "Taiwan",
  },
  {
    _id: "JP",
    fnm: "Japan",
  },
  {
    _id: "KR",
    fnm: "Korea ",
  },
  {
    _id: "CN",
    fnm: "China",
  },
  {
    _id: "DE",
    fnm: "Germany",
  },
  {
    _id: "FR",
    fnm: "France",
  },
  {
    _id: "PL",
    fnm: "Poland",
  },
  {
    _id: "BR",
    fnm: "Brazil",
  },
  {
    _id: "CL",
    fnm: "Chile",
  },
  {
    _id: "PH",
    fnm: "Philippines",
  },
  {
    _id: "CA",
    fnm: "Canada",
  },
  {
    _id: "TH",
    fnm: "Thailand",
  },
  {
    _id: "MX",
    fnm: "Mexico",
  },
  {
    _id: "GB",
    fnm: "United Kingdom",
  },
  {
    _id: "HK",
    fnm: "Hong Kong",
  },
  {
    _id: "AR",
    fnm: "Argentina",
  },
  {
    _id: "MY",
    fnm: "Malaysia",
  },
  {
    _id: "AU",
    fnm: "Australia",
  },
  {
    _id: "IT",
    fnm: "Italy",
  },
  {
    _id: "UA",
    fnm: "Ukraine",
  },
  {
    _id: "ES",
    fnm: "Spain",
  },
  {
    _id: "VN",
    fnm: "Vietnam",
  },
  {
    _id: "SG",
    fnm: "Singapore",
  },
  {
    _id: "NL",
    fnm: "Netherlands",
  },
  {
    _id: "FI",
    fnm: "Finland",
  },
  {
    _id: "SE",
    fnm: "Sweden",
  },
  {
    _id: "PE",
    fnm: "Peru",
  },
  {
    _id: "CO",
    fnm: "Colombia",
  },
  {
    _id: "VE",
    fnm: "Venezuela",
  },
  {
    _id: "TR",
    fnm: "Turkey",
  },
  {
    _id: "CZ",
    fnm: "Czechia",
  },
  {
    _id: "BY",
    fnm: "Belarus",
  },
  {
    _id: "BE",
    fnm: "Belgium",
  },
  {
    _id: "NO",
    fnm: "Norway",
  },
  {
    _id: "PT",
    fnm: "Portugal",
  },
  {
    _id: "AT",
    fnm: "Austria",
  },
  {
    _id: "HU",
    fnm: "Hungary",
  },
  {
    _id: "RO",
    fnm: "Romania",
  },
  {
    _id: "DK",
    fnm: "Denmark",
  },
  {
    _id: "KZ",
    fnm: "Kazakhstan",
  },
  {
    _id: "NZ",
    fnm: "New Zealand",
  },
  {
    _id: "LT",
    fnm: "Lithuania",
  },
  {
    _id: "CH",
    fnm: "Switzerland",
  },
  {
    _id: "IL",
    fnm: "Israel",
  },
  {
    _id: "UY",
    fnm: "Uruguay",
  },
  {
    _id: "EE",
    fnm: "Estonia",
  },
  {
    _id: "GR",
    fnm: "Greece",
  },
  {
    _id: "BG",
    fnm: "Bulgaria",
  },
  {
    _id: "EC",
    fnm: "Ecuador",
  },
  {
    _id: "SK",
    fnm: "Slovakia",
  },
  {
    _id: "LV",
    fnm: "Latvia",
  },
  {
    _id: "MO",
    fnm: "Macao",
  },
  {
    _id: "SA",
    fnm: "Saudi Arabia",
  },
  {
    _id: "RS",
    fnm: "Serbia",
  },
  {
    _id: "CR",
    fnm: "Costa Rica",
  },
  {
    _id: "IE",
    fnm: "Ireland",
  },
];

function importFlags(r: any) {
  let images: { [index: string]: any } = {};
  r.keys().map((item: string) => {
    return (images[item.replace("./", "")] = r(item));
  });
  return images;
}

const flags = importFlags((require as any).context("../img/flags", false, /\.svg/)); //webpack's require.context

function getFlagURL(country?: string) {
  return country ? flags[`${country.toLowerCase()}.svg`] : null;
}

function getDateLocal() {
  let date = new Date();
  let m = date.getMonth() + 1;
  return (
    date.getFullYear() +
    "-" +
    m.toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0")
  );
}

function add_validateDate(date: string, addDays: number, maxDate: string) {
  let datejs = dayjs(date).add(addDays, "days");
  datejs =
    datejs.isValid() && !datejs.isAfter(maxDate, "days")
      ? !datejs.isBefore(START_DATE, "days")
        ? datejs
        : dayjs(START_DATE)
      : dayjs(maxDate);
  return datejs.format("YYYY-MM-DD");
}

function positionURL(first: number = 1, last: number = 0) {
  if (first <= 1 && last <= 0) return "";
  if (first > last) return first.toString() + "-" + first.toString();

  return first.toString() + "-" + last.toString();
}

//////////////////////////////
//////////////////////////////

const RankingLayout = () => {
  const isInitialMount = useRef(true);
  const history = useHistory();
  const givenParams: string[] = history.location.pathname.split("/").slice(2); ///mode/date/country/last-first/gainsDays

  const [date, setDate] = useState(givenParams[1] || "");
  const setDateHandler = (event: any) => {
    setDate(event.target.value);
  };
  const [maxDate, setmaxDate] = useState(getDateLocal());

  const [displaySettingsVisible, setDisplaySettingsVisible] = useState(false);
  const [miscG50RankingUncollapsed, setMiscG50RankingUncollapsed] = useState(false);
  const [displayState, setDisplay]: [displayT, any] = useState({
    mode: givenParams[0] || "",
    showGained: true,
    gainsDays: Number(givenParams[4]) || 1,
    showCountry: true,
    country: givenParams[2] || "",
    firstEntry: givenParams[3] ? Number(givenParams[3].split("-")[0]) : 1,
    lastEntry: givenParams[3] ? Number(givenParams[3].split("-")[1]) : 0,
    //pages: 0,
    displayMode: "standard",
  });
  const [gainsDaysVal, setGainsDaysVal] = useState(1);
  const [gainsDaysMax, setGainsDaysMax] = useState(350);

  const [loading, setLoading] = useState(false);

  const [ranking, renderRanking]: [Array<any>, any] = useState([]);
  const [rankingNotice, renderNotice]: [JSX.Element, any] = useState(
    <p style={{ fontSize: "1.25rem" }}>
      Pick a date and click <i>yoink</i> to see the top 50 ranking from that date or click it
      now to default to today.
    </p>
  );
  const [rankingAdditional, setRankingAdditional]: [any[], any] = useState([]);
  const [rankingHeader, setRankingHeader]: [JSX.Element | null, any] = useState(null);

  const [autocmp, setAutocmp]: [Array<{ _id: string; fnm: string }>, any] = useState([]);

  const getGainsDaysNum = (mode: string, date: string) => {
    if (mode !== "gains") return 1;
    return displayState.gainsDays === "max"
      ? dayjs(date).diff(START_DATE, "days") - 1
      : displayState.gainsDays === "custom"
      ? gainsDaysVal
      : Number(displayState.gainsDays);
  };

  const handleDisplayChange_Num = (e: React.ChangeEvent<HTMLInputElement>) =>
    setDisplay({ ...displayState, [e.target.name]: Number(e.target.value) });
  const handleDisplayChange = (e: any) =>
    setDisplay({ ...displayState, [e.target.name]: e.target.value });
  const handleDisplayChange_Check = (e: React.ChangeEvent<HTMLInputElement>) =>
    setDisplay({ ...displayState, [e.target.name]: e.target.checked });
  const handleDisplayChange_GainsVal = (e: React.ChangeEvent<HTMLInputElement>) =>
    setGainsDaysVal(Number(e.target.value));

  const getMaxGainsDays = (date: string) => {
    return dayjs(date).diff(START_DATE, "days");
  };

  const gainsDaysURL = (date: string) => {
    let g = getGainsDaysNum(displayState.mode, date);
    if (g === 1) return "";
    return g;
  };

  const requestPlayers = (mode: string, date: string) => {
    let gainsDays = getGainsDaysNum(mode, date);
    if (gainsDays < 1) gainsDays = 1;
    else if (gainsDays > gainsDaysMax) gainsDays = gainsDaysMax;

    setLoading(true);
    axios
      .post(
        `/api/ranking/${mode || "players"}/${date}`,
        {
          firstEntry: displayState.firstEntry,
          lastEntry: displayState.lastEntry,
          country: displayState.country,
          showGained: displayState.showGained,
          gainsDays,
          showCountry: displayState.showCountry,
        },
        {
          responseType: "json",
        }
      )
      .then((response) => {
        //No results:
        if (
          !response.data.length ||
          (typeof response.data[0] === typeof [] &&
            Object.getPrototypeOf(response.data[0]) === Object.getPrototypeOf([]) &&
            !response.data[0].length)
        ) {
          let errMsg;
          if (maxDate <= date)
            errMsg =
              "The archive usually updates at around 10 a.m. UTC. Please check back later.";
          else
            errMsg =
              "Either osu!Stats did not update at the given date, \nyour query was invalid, or there is nothing to show.";

          renderNotice(
            <Alert
              key="alert"
              title="Fuckie Wuckie!"
              text={"Couldn't retreive the ranking from " + date + ".\n" + errMsg}
              color="yellow"
            />
          );
          setRankingHeader(null);
          renderRanking([]);
          setRankingAdditional([]);
          return;
        }
        if (mode === "" || mode === "players") {
          setRankingHeader(
            <h3 className="ranking-header">
              Top 50s ranking on {dayjs(date).format("MMMM D, YYYY")}
            </h3>
          );
          renderRanking(response.data);
        } else if (mode === "countries") {
          setRankingHeader(null);
          renderRanking(response.data);
        } else if (mode === "gains") {
          let radditional = response.data.slice(1); //numPlayers & gainsDays
          setRankingHeader(
            <h3 className="ranking-header">
              Most gained top 50s from{" "}
              <span className="date-em">
                {dayjs(date).subtract(Number(radditional[1]), "day").format("MMMM D YYYY")}
              </span>{" "}
              to <span className="date-em">{dayjs(date).format("MMMM D YYYY")}</span>
            </h3>
          );
          setRankingAdditional(radditional);
          renderRanking(response.data[0]);
        } else if (mode === "misc") {
          setRankingHeader(
            <h3 className="ranking-header">
              Miscellaneous stats for {dayjs(date).format("MMMM D, YYYY")}
            </h3>
          );
          setRankingAdditional([response.data[0]]);
          renderRanking([]);
        }
        renderNotice(null);
      })
      .catch(function (error) {
        setRankingHeader(null);

        let errMsg: string = "Failed to render the results.";
        if (error.response) errMsg = error.response.statusText || "Server went oopsie...";

        renderNotice(
          <Alert
            title="Fuckie Wuckie!"
            text={"Couldn't retreive the ranking from " + date + ".\n" + errMsg}
            color="red"
          />
        );
        renderRanking([]);
      })
      .finally(() => setLoading(false));
  };

  const sendRequestPlayers = (addDays: number, force?: boolean) => {
    let validatedDate = add_validateDate(date, addDays, maxDate);
    if (force || (validatedDate && validatedDate !== date)) {
      requestPlayers(displayState.mode, validatedDate);
      setDate(validatedDate);
      history.push(
        "/ranking/" +
          displayState.mode +
          "/" +
          validatedDate +
          "/" +
          displayState.country +
          "/" +
          positionURL(displayState.firstEntry, displayState.lastEntry) +
          "/" +
          gainsDaysURL(validatedDate)
      );
      document.title = `(${validatedDate}) poggers ranking`;
    }
  };

  //TODO: debounce
  const searchCountry = (name: string) => {
    const reg = new RegExp(name, "i");
    const result: countryT[] = [];
    for (const i in countries) {
      if (reg.test(countries[i]._id) || reg.test(countries[i].fnm)) {
        result.push(countries[i]);
        if (result.length === 5) break;
      }
    }
    setAutocmp(result);
  };

  const setSecondNavbarActiveBar = (e?: Element) => {
    if (!e) e = document.getElementsByClassName("tab-active")[1];
    const rect = e.getBoundingClientRect();
    e.parentElement?.style.setProperty("--w", rect.width + "px");
    e.parentElement?.style.setProperty("--x", rect.x + "px");
  };

  const handleCountryAutocmp = (country: string) => {
    setAutocmp([]);
    setDisplay({ ...displayState, country: country });
  };

  function handleResize() {
    setSecondNavbarActiveBar();
  }
  function handleClick(e: any) {
    if (!(e.target as Element).className.includes("autocmp-cntry")) setAutocmp([]);
  }

  useEffect(() => {
    //onMount
    if (isInitialMount.current) {
      isInitialMount.current = false;
      document.title = "poggers ranking";

      document.addEventListener("mousedown", handleClick);
      window.addEventListener("resize", handleResize);
      setSecondNavbarActiveBar();

      axios
        .get("/api/site/date")
        .then((response) => {
          setmaxDate(response.data);
        })
        .finally(() => {
          if (givenParams[1]) sendRequestPlayers(0, true);
        });
    } else {
      sendRequestPlayers(0, true);
    }

    // eslint-disable-next-line
  }, [displayState.mode]);

  useEffect(() => {
    //cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setGainsDaysMax(getMaxGainsDays(date || maxDate));
    // eslint-disable-next-line
  }, [date]);

  //////////////////////RENDER
  //////////////////////RENDER
  return (
    <>
      <SecondaryNavbar
        tabs={modeTabs}
        activeTab={displayState.mode || "players"}
        tabClick={(e: any) => {
          const t = e.target;
          setSecondNavbarActiveBar(t);

          setDisplay({ ...displayState, mode: t.innerHTML.toLowerCase() });
        }}
        margin="2rem"
      />

      <div className="container container-main">
        <h1 style={{ marginTop: 0, fontWeight: 400 }}>Top 50s Ranking Archive</h1>
        <DateToolbar
          dateChange={setDateHandler}
          sendDateChange={sendRequestPlayers}
          date={date}
          maxDate={maxDate}
        />

        {/* SETTINGS */}
        <button
          className="btn btn-gray"
          style={{ margin: "10px 0" }}
          onClick={() => {
            setDisplaySettingsVisible(!displaySettingsVisible);
          }}
        >
          Settings
        </button>
        {/* @ts-ignore */}
        <Collapse
          isOpened={displaySettingsVisible}
          theme={{
            collapse: "ReactCollapse--collapse",
            content: "ReactCollapse--content-ranking",
          }}
        >
          {displayState.mode !== "countries" && displayState.mode !== "misc" ? (
            <label>
              Show countries
              <input
                name="showCountry"
                onChange={handleDisplayChange_Check}
                type="checkbox"
                checked={displayState.showCountry}
              />
            </label>
          ) : null}
          {displayState.mode === "" || displayState.mode === "players" ? (
            <label>
              Show gained
              <input
                name="showGained"
                onChange={handleDisplayChange_Check}
                type="checkbox"
                checked={displayState.showGained}
              />
            </label>
          ) : null}
          <fieldset style={{ marginLeft: "10%", marginRight: "10%" }}>
            <legend>Rank Range</legend>
            <input
              name="firstEntry"
              onChange={handleDisplayChange_Num}
              type="number"
              min="0"
              placeholder="From"
              value={displayState.firstEntry}
              style={{ width: "32%" }}
            />
            <span className="ml-1 mr-1">To</span>
            <input
              name="lastEntry"
              onChange={handleDisplayChange_Num}
              type="number"
              min="0"
              placeholder="To"
              value={displayState.lastEntry}
              style={{ width: "32%" }}
            />
          </fieldset>
          {displayState.mode !== "countries" && displayState.mode !== "misc" ? (
            <div className="autocmp-wrapper">
              <input
                name="country"
                // TODO: debounce
                onChange={(e) => {
                  setDisplay({ ...displayState, country: e.target.value });
                  if (e.target.value.length >= 2) searchCountry(e.target.value);
                  else setAutocmp([]);
                }}
                type="text"
                placeholder="Country"
                value={displayState.country}
                autoComplete="off"
              />
              <div className="autocmp-items" style={{ marginTop: "-0.41rem" }}>
                {autocmp.map((cntry) => {
                  return (
                    <div
                      key={cntry._id}
                      className="autocmp autocmp-cntry"
                      tabIndex={0}
                      onClick={() => {
                        handleCountryAutocmp(cntry._id);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleCountryAutocmp(cntry._id);
                        }
                      }}
                    >
                      <img
                        className="small-img flag mr-2"
                        src={getFlagURL(cntry._id)}
                        alt=""
                      ></img>
                      {cntry.fnm}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {displayState.mode === "gains" ? (
            <>
              <br />
              <fieldset style={{ marginLeft: "10%", marginRight: "10%" }}>
                <legend>Gains time frame</legend>
                <select name="gainsDays" onChange={handleDisplayChange}>
                  <option value="1">1 Day</option>
                  <option value="7">1 Week</option>
                  <option value="14">2 Weeks</option>
                  <option value="30">1 Month</option>
                  <option value="183">6 Months</option>
                  <option value="max">Maximum</option>
                  <option value="custom">Custom</option>
                </select>

                {displayState.mode === "gains" && displayState.gainsDays === "custom" ? (
                  <>
                    <br />
                    <input
                      type="number"
                      name="gainsDaysVal"
                      placeholder="Days"
                      min="1"
                      max={gainsDaysMax}
                      onChange={handleDisplayChange_GainsVal}
                    />
                  </>
                ) : null}
              </fieldset>
            </>
          ) : null}
        </Collapse>
        {rankingNotice}
        {rankingHeader}

        {/* RANKING */}
        {loading && <div className="loader loader-sticky"></div>}
        <div className="wrapper">
          {loading && <div className="overlay"></div>}
          {ranking.length ? (
            (displayState.mode === "" || displayState.mode === "players") && ranking[0].pos ? (
              <table style={{ width: "100%", overflowX: "hidden" }}>
                <tbody>
                  {ranking.map((e: playerData) => (
                    <RankingEntry
                      key={e._id}
                      showCountry={displayState.showCountry}
                      showGains={displayState.showGained}
                      playerData={e}
                      flag={getFlagURL(e.cntr)}
                    />
                  ))}
                </tbody>
              </table>
            ) : displayState.mode === "gains" &&
              ranking[0].g50 &&
              ranking[0].pos === undefined ? (
              <table style={{ width: "100%" }}>
                <tbody>
                  {ranking.map((e: playerData, index) => {
                    return (
                      <Fragment key={e._id}>
                        {e.g50 < 0 ? (
                          <tr className="ranking-dots">
                            <td>. . .</td>
                          </tr>
                        ) : null}
                        <RankingEntry
                          showCountry={displayState.showCountry}
                          showGains={true}
                          playerData={e}
                          flag={getFlagURL(e.cntr)}
                          gainsDays={rankingAdditional[1]}
                          pos={
                            e.g50 >= 0
                              ? index + 1
                              : rankingAdditional[0] - ranking.length + index + 1
                          }
                        />
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            ) : displayState.mode === "countries" && ranking[0].nm ? (
              <RankingCountryTable data={ranking} flags={flags} />
            ) : null
          ) : displayState.mode === "misc" && rankingAdditional[0]?.daysTrack ? (
            <>
              <div className="misc-container">
                {rankingAdditional[0].tTot ? (
                  <>
                    <div className="misc-stat">
                      <h3>Total</h3>
                      <div style={{ margin: "1rem 0" }} className="row">
                        <span className="misc-stat-value">
                          {formatNumber(rankingAdditional[0].tTot)}
                        </span>
                        <span style={{ fontWeight: 300 }}>/4 675 000</span>
                      </div>
                      <small>assuming 93.5k maps with leaderboards</small>
                    </div>
                    <div className="misc-stat">
                      <h3>Players</h3>
                      <span className="misc-stat-value">
                        {formatNumber(rankingAdditional[0].tAmount)}
                      </span>
                      <small>with {rankingAdditional[0].tMin} or more top 50s</small>
                    </div>
                    <div className="misc-stat">
                      <h3>Average</h3>
                      <span className="misc-stat-value">
                        {formatNumber(
                          Math.round(
                            (rankingAdditional[0].tTot / rankingAdditional[0].tAmount) * 100
                          ) / 100
                        )}
                      </span>
                    </div>
                    <div className="misc-stat">
                      <h3>Median</h3>
                      <span className="misc-stat-value">
                        {formatNumber(rankingAdditional[0].tMedian)}
                      </span>
                    </div>
                  </>
                ) : null}
                <div className="misc-stat">
                  <h3>Tracked since</h3>
                  <span className="misc-stat-value">
                    {rankingAdditional[0].daysTot + " days ago"}
                  </span>
                  <small>
                    {rankingAdditional[0].daysTrack +
                      " data points (" +
                      Math.round(
                        (rankingAdditional[0].daysTrack / rankingAdditional[0].daysTot) * 10000
                      ) /
                        100 +
                      "%)"}
                  </small>
                </div>
              </div>
              <p style={{ fontSize: "1rem", fontWeight: 300 }}>Not finished yet &gt;_&gt;</p>
              <h2 style={{ fontWeight: 300, marginBottom: 0 }}>
                Most top 50s gained in one day
              </h2>
              <hr style={{ marginTop: 0 }}></hr>
              <table style={{ width: "100%", overflow: "hidden" }}>
                <tbody>
                  <RankingEntry
                    key={0}
                    pos={1}
                    playerData={rankingAdditional[0].g50[0]}
                    showCountry={displayState.showCountry}
                    showGains={true}
                    flag={getFlagURL(rankingAdditional[0].g50[0].cntr)}
                  />
                  {/* validateDOMNesting Warning */}
                  {/* @ts-ignore */}
                  <Collapse isOpened={miscG50RankingUncollapsed}>
                    {rankingAdditional[0].g50.map((e, index) => {
                      if (!index) return null;
                      return (
                        <RankingEntry
                          key={index}
                          pos={e._id}
                          playerData={e}
                          showCountry={displayState.showCountry}
                          showGains={true}
                          flag={getFlagURL(e.cntr)}
                        />
                      );
                    })}
                  </Collapse>
                </tbody>
              </table>
              <div
                className="icon icon-big icon-expand icon-hoverable"
                style={{
                  margin: "0 auto",
                  transform: miscG50RankingUncollapsed ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "transform 0.26s",
                }}
                onClick={() => setMiscG50RankingUncollapsed(!miscG50RankingUncollapsed)}
              ></div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default RankingLayout;
