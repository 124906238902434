import React, { Component } from "react";

type props = {
  btnTxt?: string;
  href?: string;
  btnCardImg?: string;
  tooltip?: string;
  imgPosY?: string;
  btnCardColor: string;
};

export default class CardButton extends Component<props> {
  render() {
    return (
      <div
        className={this.props.tooltip ? "btn-card-main tooltip" : "btn-card-main"}
        data-tooltip={this.props.tooltip}
      >
        <a href={this.props.href}>
          <div
            className="btn-card"
            style={{
              backgroundColor: this.props.btnCardColor,
              backgroundImage: this.props.btnCardImg,
              backgroundPositionY: this.props.imgPosY || "center",
            }}
          ></div>
          <div className="btn-card-label">{this.props.btnTxt}</div>
        </a>
      </div>
    );
  }
}
