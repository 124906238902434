import React, { Component } from "react";

export default class Datepicker extends Component<{
  max?: string;
  min?: string;
  date?: string;
  onchange: any;
  onkeyenter: any;
}> {
  handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") this.props.onkeyenter();
  };

  render() {
    return (
      <input
        className="datepicker"
        type="date"
        value={this.props.date}
        min={this.props.min}
        max={this.props.max}
        onChange={this.props.onchange}
        onKeyDown={this.handleEnterKey}
      ></input>
    );
  }
}
