import React, { Component } from "react";
import "./About.css";

export default class AboutLayout extends Component {
  componentDidMount() {
    document.title = "about poggers";
  }

  render() {
    return (
      <div className="container-main">
        <h2 style={{ textAlign: "center" }}>What is this?</h2>
        <h1>General</h1>
        <p className="answer">
          This is a work-in-progress website being made by a leaderboard farmer for leaderboard
          farmers. It uses images from{" "}
          <a className="site-link" href="https://osu.ppy.sh" target="_blank" rel="noreferrer">
            osu.ppy.sh
          </a>
          , flags from{" "}
          <a
            className="site-link"
            href="https://github.com/lipis/flag-icon-css"
            target="_blank"
            rel="noreferrer"
          >
            this repo
          </a>
          , and data from{" "}
          <a
            className="site-link"
            href="https://osustats.ppy.sh"
            target="_blank"
            rel="noreferrer"
          >
            osustats.ppy.sh
          </a>
          . 90% of the credit goes to{" "}
          <a
            className="player-link"
            href="https://osu.ppy.sh/users/304520"
            target="_blank"
            rel="noreferrer"
          >
            Piotrekol
          </a>{" "}
          and{" "}
          <a
            className="player-link"
            href="https://osu.ppy.sh/users/1231180"
            target="_blank"
            rel="noreferrer"
          >
            Ezoda
          </a>{" "}
          and their osu!stats, the other 10% goes to stack overflow.
          <br /> <br />
          This is an on-going rewrite of pogowanie to improve uptime, performance, and
          functionality. Everything besides the fetcher is in place, but there's still quite{" "}
          <a className="site-link" href="changelog">
            a lot to do
          </a>
          .
          <br />
          The previous version is deprecated (except the fetcher), but still available at{" "}
          <a
            className="site-link"
            href="https://pogowany.000webhostapp.com/"
            target="_blank"
            rel="noreferrer"
          >
            pogowany.000webhostapp.com
          </a>
          .
          <br />
          <br />
        </p>
        Current features include:
        <ul>
          <li>
            A global top 50s count ranking tracking every player with at least a 1000 top 50s
          </li>
          <li>Browsing said ranking from any day since May 10 2020</li>
          <li>
            Additional stats such as most gained top 50s in a given time frame or countries with
            the highest top 50s count
          </li>
          <li>
            'Top 50s over time' chart simplifying the visualization and comparison of players'
            top 50s count during a chosen time frame
          </li>
          <li>Why are you still reading this</li>
          <li>Most top 50s gained in one day ranking</li>
          <li>
            Basic data filtering (eg. showing only players between set ranks from a single
            country)
          </li>
          <li>Senko poggers :)</li>
          <li>and probably more...</li>
        </ul>
        <br />
        <h1>osu!stats ranking archive</h1>
        <h2 id="why-though">Why are there no scores at a given date?</h2>
        <p className="answer">
          If you're looking for today's ranking, it's probably just not there yet. It usually
          updates around 10 a.m. UTC.
          <br />
          Otherwise, the only reason that's happened is because of osu!stats being down/not
          updating. Nothing I can do about it.
        </p>
        <h2 id="what-ranking">What does it do?</h2>
        <p className="answer">
          It takes the data of all players, who have over 1000 scores in top 50, from osu!stats'
          #1-#50 score ranking, stores it in a database and makes it possible to access said
          ranking from any day. Additionally, it provides an easy way to track progress through
          charts and whatnot.
        </p>
        <h2 id="how-ranking">How does it work?</h2>
        <p className="answer">barely;</p>
        <br />
        <h1>osu!stats fetcher</h1>
        Currently only available on the old website at{" "}
        <a
          className="site-link"
          href="https://pogowany.000webhostapp.com/fetcher"
          target="_blank"
          rel="noreferrer"
        >
          pogowany.000webhostapp.com
        </a>
        .<h2>Why the heck does it take so long to show my statistics?</h2>
        <p className="answer">
          Probably because osu!stats has a lot of data to work with, and it takes time. Patience
          is a virtue ...unless it's taking over 50 seconds. If that's the case, then something
          probably went wrong. Refresh the page and try again in a bit.
        </p>
        <h2 id="what-fetcher">What does it do?</h2>
        <p className="answer">
          Right now it's a very crude tool that allows you to see how many individual ranks any
          player has. 'Pog all' returns a chart with the amount of scores from #1 through #50
          and a merge of #51 to #100. For the time being it only works with osu!standard.
        </p>
        <br />
        <h1>Other osu! stat-tracking sites</h1>
        <a
          className="site-link"
          href="https://osustats.ppy.sh"
          target="_blank"
          rel="noreferrer"
        >
          <b>osu!Stats</b>
        </a>{" "}
        - the site this entire thing is based on
        <br />
        <a
          className="site-link"
          href="https://snipe.huismetbenen.nl"
          target="_blank"
          rel="noreferrer"
        >
          <b>osu!snipe</b>
        </a>{" "}
        - country #1s
        <br />
        <a
          className="site-link"
          href="https://ameobea.me/osutrack"
          target="_blank"
          rel="noreferrer"
        >
          <b>osu!track</b>
        </a>{" "}
        - profile stats tracking with satisfying charts
        <br />
        <a className="site-link" href="https://osudaily.net" target="_blank" rel="noreferrer">
          <b>osu!daily</b>
        </a>{" "}
        - various rankings like playcount, SS count etc. + daily profile stats tracking similar
        to osu!track
        <br />
        <a className="site-link" href="http://osuskills.com" target="_blank" rel="noreferrer">
          <b>osu!Skills</b>
        </a>{" "}
        - pp ranking but more broken
        <br />
        <br />
        <h1>Help, something broke</h1>
        Shoot me a message up on <span className="discord">Discord</span>: @hi#8112
        <br />
        <br />
        <br />
        <small>I need sleep</small>
      </div>
    );
  }
}
