import React from "react";

type SecondaryTabProps = {
  text: string;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
};

const Tab = (props: SecondaryTabProps) => {
  let classes = "tab";
  if (props.active) classes += " tab-active";

  return (
    <span tabIndex={0} className={classes} onClick={props.onClick}>
      {props.text}
    </span>
  );
};

export class SecondaryNavbar extends React.Component<{
  tabs: string[];
  activeTab: string;
  margin: string;
  tabClick?: React.MouseEventHandler<HTMLSpanElement>;
}> {
  render() {
    return (
      <nav className="navbar navbar-secondary" style={{ marginBottom: this.props.margin }}>
        {this.props.tabs.map((t) => (
          <Tab
            key={t}
            text={t}
            active={this.props.activeTab === t.toLowerCase()}
            onClick={this.props.tabClick}
          />
        ))}
      </nav>
    );
  }
}
