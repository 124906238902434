import { PureComponent, Fragment } from "react";
import dayjs from "dayjs";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

const dateFormatter = (date: number) => dayjs(date).format("YYYY-MM-DD");
const dateFormatterTooltip = (date: number) => dayjs(date).format("D MMM YYYY");

type TcommonProps = {
  type: any;
  strokeWidth: number;
  dot: boolean;
  connectNulls: boolean;
  animationDuration: number;
};

const commonProps: TcommonProps = {
  type: "monotone",
  strokeWidth: 3,
  dot: false,
  connectNulls: true,
  animationDuration: 700,
};

const tooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="chart-stats-tooltip">
        <span style={{ fontSize: "85%", fontWeight: 750 }}>{dateFormatterTooltip(label)}</span>
        {payload.map((field: any, key: number) => {
          const name = field.name.split("'s");
          return (
            <Fragment key={key}>
              <br />
              <span style={{ color: field.color }}>{name[0]}</span>
              {"'s" + name[1] + ": "}
              {field.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </Fragment>
          );
        })}
      </div>
    );
  }

  return null;
};

function compareByDate(a: { Date: number }, b: { Date: number }) {
  return a.Date < b.Date ? 1 : -1;
}

function mergeDataArrays(arrays: any[]) {
  //takes mapped arrays of players and merges and sorts them by date
  let merged: any = {};

  for (const a in arrays) {
    //players
    for (const b in arrays[a]) {
      //players' stats
      merged[arrays[a][b].Date] = {
        ...merged[arrays[a][b].Date],
        ["t50-" + a]: arrays[a][b]["t50-" + a],
        ["pos-" + a]: arrays[a][b]["pos-" + a],
      };
    }
  }

  let result: any[] = [];
  for (const Date in merged) {
    result.push({ Date: Number(Date), ...merged[Date] });
  }

  return result.sort(compareByDate);
}

export default class UserStatsChart extends PureComponent<{
  data: any;
  colors: Array<{ t: string; r: string }>;
}> {
  render() {
    let maxRank = 1;
    return (
      <ResponsiveContainer>
        <AreaChart
          data={
            this.props.data[0] &&
            this.props.data[0].stats &&
            mergeDataArrays(
              this.props.data.map((user: any, index: number) => {
                let t = user.vis_t,
                  r = user.vis_r;
                return t || r
                  ? user.stats.map((stats: any) => {
                      let pdata: { Date: string; [key: string]: any } = { Date: stats.Date };
                      if (t) pdata["t50-" + index] = stats["t50-" + index];
                      if (r) {
                        pdata["pos-" + index] = stats["pos-" + index];
                        if (stats["pos-" + index] > maxRank) maxRank = stats["pos-" + index];
                      }
                      return pdata;
                    })
                  : null;
              })
            )
          }
          margin={{
            top: 0,
            right: 10,
            left: 10,
            bottom: 22,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis
            dataKey="Date"
            scale="time"
            type="number"
            domain={["auto", "auto"]}
            tickFormatter={dateFormatter}
            allowDecimals={false}
            angle={345}
            tickLine={false}
            tickMargin={10}
          />
          <YAxis
            yAxisId="top50"
            scale="linear"
            type="number"
            domain={[(dataMin: any) => Math.max(dataMin - 200, 1000), "dataMax + 200"]}
            allowDecimals={false}
          />
          <YAxis
            yAxisId="rank"
            scale="linear"
            type="number"
            orientation="right"
            tickFormatter={(value) => (value - value.toFixed(0) ? "" : value)}
            domain={[(dataMin: any) => Math.max(dataMin - 1, 1), "dataMax + 1"]}
            allowDecimals={false}
            reversed={true}
          />
          <Tooltip content={tooltip} />

          {this.props.data[0] &&
            this.props.data.map((user: any, index: number) => {
              return user.vis_t ? (
                <Area
                  key={"t" + user.id}
                  {...commonProps}
                  yAxisId={"top50"}
                  dataKey={"t50-" + index}
                  name={user.name + "'s Top 50s"}
                  stroke={this.props.colors[index % this.props.colors.length].t}
                  fill={this.props.colors[index % this.props.colors.length].t}
                  fillOpacity={0.2}
                />
              ) : null;
            })}
          {this.props.data[0] &&
            this.props.data.map((user: any, index: number) => {
              return user.vis_r ? (
                <Area
                  key={"p" + user.id}
                  {...commonProps}
                  yAxisId={"rank"}
                  dataKey={"pos-" + index}
                  name={user.name + "'s Rank"}
                  stroke={this.props.colors[index % this.props.colors.length].r}
                  fillOpacity={0}
                />
              ) : null;
            })}
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
