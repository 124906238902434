import React, { Component } from "react";

export class FetcherLayout extends Component {
  componentDidMount() {
    document.title = "poggers - fetcher";
  }

  render() {
    return (
      <div className="container container-main">
        <h1>osu!Stats Score Fetcher</h1>
        <p>how are you?</p>
        <p>
          Currently only available at{" "}
          <a href="https://pogowany.000webhostapp.com/fetcher">pogowany.000webhostapp.com</a>
        </p>
      </div>
    );
  }
}

export default FetcherLayout;
